import { Component } from 'react';
import { toDataSourceRequest, toDataSourceRequestString } from '@progress/kendo-data-query';
import authService from '../api-authorization/AuthorizeService';
import { IClass } from '../interfaces/IClassEvent';
import { IClub } from '../interfaces/IClub';
import IDiscipline from '../interfaces/IDiscipline';
import IGrade from '../interfaces/IGrade';
import IGradeCategory from '../interfaces/IGradeCategory';
import ILocation from '../interfaces/ILocation';
import { ILookup } from '../interfaces/ILookup';
import IMarketingCampaign from '../interfaces/IMarketingCampaign';
import IMessageLayout from '../interfaces/IMessageLayout';
import IMessageTemplate from '../interfaces/IMessageTemplate';
import { IProductCategory, IProductSummary } from '../interfaces/IProduct';
import ITrainingPlan from '../interfaces/ITrainingPlan';
import { provideIntlService } from '@progress/kendo-react-intl';
import IMemberCategory from '../interfaces/IMemberCategory';
import IStandardResponse from '../interfaces/IStandardResponse';

export default class BaseService {
  
  readonly comm_flag_sms: number = 1;
  readonly comm_flag_email: number = 2;
  readonly comm_flag_app: number = 4;
  readonly comm_flag_photo: number = 8;
  readonly comm_flag_other: number = 16;

  readonly member_status_active: number = 21;
  readonly member_status_prospect: number = 22;
  readonly member_status_lapsed: number = 23;
  readonly member_status_issue: number = 24;
  readonly member_status_not_interested: number = 46;
  readonly member_status_enquiry: number = 872;
  readonly member_status_unconverted: number = 912;

  readonly payment_method_gocardless: number = 186;
  readonly payment_method_instantbankpay: number = 926;
  readonly payment_method_stripe: number = 946;

  readonly message_parameter_club_id: number = 939;
  readonly message_parameter_member_status: number = 126;
  readonly message_parameter_category: number = 127;
  readonly message_parameter_class: number = 133;
  readonly message_parameter_tags: number = 940;
  readonly message_parameter_refs: number = 140;
  readonly message_parameter_disciplines: number = 120;
  readonly message_parameter_grade_from: number = 875;
  readonly message_parameter_grade_to: number = 876;
  readonly message_parameter_candidate: number = 941;
  readonly message_parameter_role: number = 135;
  readonly message_parameter_user: number = 136;

  readonly action_type_rebook_different = 903;
  readonly action_type_rebook_future = 902;
  readonly action_type_rebook_next = 901;
  readonly action_type_not_interested = 900;
  readonly action_type_activated = 899;

  readonly class_type_class = 39;
  readonly class_type_seminar = 40;
  readonly class_type_workshop = 41;
  readonly class_type_grading = 44;
  readonly class_type_competition = 45;
  readonly class_type_chargable = 148;
  readonly class_type_course = 863;
  readonly class_type_nonattendance = 865;
  readonly class_type_prebooked = 915;

  getIntl(c: Component) {
    let ret = provideIntlService(c);
    ret.locale = "en-GB";
    return ret;
  }

  async getApiCall<Type>(url: string) {

    let token = await authService.getAccessToken();
    const response = await fetch(url, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });

    // Check for a forbidden
    if(response.status === 403) {
      throw new Error("Not authorised!", { cause: response.status });
    }

    // Check for a no auth
    if(response.status === 401) {
      throw new Error("Not authenticated!", { cause: response.status });
    }

    const data = await response.json() as Promise<Type>;
    return data;
  }

  async postApiCall<Type>(url: string, body: any) {

    let token = await authService.getAccessToken();

    const response = await fetch(url, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      method: 'POST',
      body: JSON.stringify(body),      
    });
    
    if(!response.ok) {
      throw new Error("Api call failed", { cause: response.status });
    }

    const data = await response.json() as Promise<Type>;
    return data;
  }

  /*
    Makes a post that expects back a standard response.
    Includes error handling
  */
  async postStandardApiCall(url: string, body: any): Promise<IStandardResponse> {

    const response = this.postApiCall<IStandardResponse>(url, body).catch((err) => {

      let err2 = err as Error;
      const response: IStandardResponse = {
        isSuccess: false,
        message: err2.message
      };

      return response;
    });

    return response;       
  }

  async getODataApiCall<Type>(url: string, dataState: any): Promise<Type> {
    
    const token = await authService.getAccessToken();
    const query = `${toDataSourceRequestString(dataState)}`;

    let full_url = `${url}?${query}`;
    if (url.includes('?')) {
      full_url = `${url}&${query}`;
    }

    const response = await fetch(full_url, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` },      
      method: 'GET'      
    });

    if(response.status === 403) {
      throw new Error("Not authorised!", { cause: response.status });
    }
    
    const data = await response.json() as Promise<Type>;
    return data;
    
  }

  async postODataApiCall<Type>(url: string, body: any, dataState: any): Promise<Type> {

    const token = await authService.getAccessToken();
    
    let filter = toDataSourceRequest(dataState);    
    let bodyWithFilter = {
      GridOptions: { ...filter },
      ...body
    };

    const response = await fetch(url, {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      method: 'POST',      
      body: JSON.stringify(bodyWithFilter)
    });

    const data = await response.json() as Promise<Type>;
    return data;
  }

  findLocationDdl(locationId: number, locations: ILocation[]): ILocation {

    let locationDdl: ILocation | undefined = locations.find(l => l.locationId == locationId);    
    if (locationDdl == undefined) { locationDdl = locations[0]; }

    return locationDdl;
  }

  findLookupDdl(lookupId: number, lookups: ILookup[]): ILookup {

    let lookupDdl: ILookup | undefined = lookups.find(l => l.id == lookupId);
    if (lookupDdl == undefined) { lookupDdl = lookups[0]; }

    return lookupDdl;
  }

  findLookupDdlByValue(lookupValue: string, lookups: ILookup[]): ILookup {

    let lookupDdl: ILookup | undefined = lookups.find(l => l.value == lookupValue);
    if (lookupDdl == undefined) { lookupDdl = lookups[0]; }

    return lookupDdl;
  }

  findDisciplineDdl(disciplineId: number, disciplines: IDiscipline[]): IDiscipline {
    let discDdl: IDiscipline | undefined = disciplines.find(l => l.disciplineId == disciplineId);
    if (discDdl == undefined) { discDdl = disciplines[0]; }

    return discDdl;
  }

  findGradeDdl(gradeId: number, grades: IGrade[]): IGrade {
    let gradeDdl: IGrade | undefined = grades.find(g => g.gradeId == gradeId);
    if (gradeDdl == undefined) { gradeDdl = grades[0]; }

    return gradeDdl;
  }

  findGradeCategoryDdl(categoryId: number, gradeCategories: IGradeCategory[]): IGradeCategory {
    let ddl: IGradeCategory | undefined = gradeCategories.find(l => l.gradeCategoryId == categoryId);
    if (ddl == undefined) { ddl = gradeCategories[0]; }

    return ddl;
  }

  findMemberCategoryDdl(categoryId: number, member_categories: IMemberCategory[]): IMemberCategory {
    let ddl: IMemberCategory | undefined = member_categories.find(l => l.memberCategoryId == categoryId);
    if (ddl == undefined) { ddl = member_categories[0]; }

    return ddl;
  }

  findClubDdl(clubId: number, clubs: IClub[]): IClub {
    let ddl: IClub | undefined = clubs.find(l => l.id == clubId);
    if (ddl == undefined) { ddl = clubs[0]; }

    return ddl;
  }

  findTrainingPlanDdl(trainingPlanId: number, plans: ITrainingPlan[]): ITrainingPlan | undefined {
    let ddl: ITrainingPlan | undefined = plans.find(l => l.id == trainingPlanId);
    if (ddl == undefined && plans.length > 0) { ddl = plans[0]; }

    return ddl;
  }

  findMarketingDdl(marketingSourceId: number, campaigns: IMarketingCampaign[]): IMarketingCampaign | undefined {
    let ddl: IMarketingCampaign | undefined = campaigns.find(c => c.id == marketingSourceId);
    if (ddl == undefined && campaigns.length > 0) { ddl = campaigns[0]; }

    return ddl;
  }

  findTemplateDdl(templateId: number, templates: IMessageTemplate[]): IMessageTemplate {
    let ddl: IMessageTemplate | undefined = templates.find(t => t.id == templateId);
    if (ddl == undefined && templates.length > 0) { ddl = templates[0]; }

    if (ddl == undefined) {
      ddl = new IMessageTemplate();
    }

    return ddl;
  }

  findLayoutDdl(layoutId: number, layouts: IMessageLayout[]): IMessageLayout {
    let ddl: IMessageLayout | undefined = layouts.find(t => t.id == layoutId);
    if (ddl == undefined && layouts.length > 0) { ddl = layouts[0]; }

    if (ddl == undefined) {
      ddl = new IMessageLayout();
    }

    return ddl;
  }

  findProductDdl(productId: number, products: IProductSummary[]) {
    let ddl: IProductSummary | undefined = products.find(t => t.productId == productId);
    if (ddl == undefined && products.length > 0) { ddl = products[0]; }

    if (ddl == undefined) {
      ddl = new IProductSummary();
    }
    return ddl;
  }

  findProductCategoryDdl(categoryId: number, categories: IProductCategory[]) {
    let ddl: IProductCategory | undefined = categories.find(t => t.id == categoryId);
    if (ddl == undefined && categories.length > 0) { ddl = categories[0]; }

    if (ddl == undefined) {
      ddl = new IProductCategory();
    }
    return ddl;
  }

  findClassDdl(classId: number, classes: IClass[]) {
    let ddl: IClass | undefined = classes.find(t => t.classId == classId);
    if (ddl == undefined && classes.length > 0) { ddl = classes[0]; }

    if (ddl == undefined) {
      ddl = new IClass();
    }
    return ddl;
  } 

  getLastMonday(dateFrom: Date): Date {
    let monday = new Date(dateFrom);
    monday.setDate(monday.getDate() - (monday.getDay() + 6) % 7);    
    return monday;
  }

  fixDate(date?: Date) {
    date?.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  }
}